import axios from "axios";
import { FIREBASE_URL_AI_GENERATOR } from "../constants";

export const aiGeneratorService = async (params) => {
  const api_key =
    "sk-c1v-LZ8kYRJzJa_hV9SpfL6WdjfwUHs4nWD3HpVkybT3BlbkFJ5krgm1Lv9EW9mtNoTsBLsbjHPO0d323q9EtiqeOjQA";
  let prompt, systemContent;

  // Determine the ad type and prompt based on searchTypeGeneral and choice
  if (params.searchTypeGeneral === "facebook") {
    if (params.choice === "Simple Facebook Ad") {
      prompt = `Write a creative ad for the following product to run on Facebook.\nProduct: ${params.productName}\nDescription: ${params.productDescription}`;
    } else if (params.choice === "Advanced Facebook Ad") {
      prompt = `Write a creative ad for the following product to run on Facebook.\nProduct: ${params.productName}\nDescription: ${params.productDescription}\n\nPain: [Describe the pain point]\nAgitate: [Describe how the pain point affects the user]\nSolution: [Describe how the product solves the pain point]`;
    } else if (params.choice === "Facebook Headlines") {
      prompt = `Write a creative ad for the following product to run on Facebook Ads.\nProduct: ${params.productName}\nDescription: ${params.productDescription}\n\nHeadline 1:\nHeadline 2:\nHeadline 3:`;
    } else {
      return "Invalid choice for Facebook ad. Please choose a valid option.";
    }
    systemContent =
      "You are a creative ad copywriter specializing in Facebook ads.";
  } else if (params.searchTypeGeneral === "GOOGLE") {
    prompt = `Write a creative ad for the following product to run on Google.\n\nProduct: ${params.productName}\nAbout: ${params.productDescription}\n\nHeadline 1:\nHeadline 2:\nHeadline 3:\nDescription 1:\nDescription 2:`;
    systemContent =
      "You are a creative ad copywriter specializing in Google ads.";
  } else {
    return "Invalid ad type. Please choose 'Facebook' or 'Google'.";
  }

  try {
    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${api_key}`, // Replace `api_key` with your actual API key
      },
      body: JSON.stringify({
        model: "gpt-4",
        messages: [
          { role: "system", content: systemContent },
          { role: "user", content: prompt },
        ],
        max_tokens: 350,
        n: 1,
        stop: null,
        temperature: 0.7,
      }),
    });

    if (response.ok) {
      const result = await response.json();
      return result.choices[0].message.content.trim();
    } else {
      const errorContent = await response.text();
      throw new Error(`API request failed: ${errorContent}`);
    }
  } catch (error) {
    throw new Error(`An error occurred: ${error.message}`);
  }

  // try {
  //   const response = await axios.post(
  //     "http://127.0.0.1:8000/generate-ad",
  //     params
  //   );
  //   console.log("response of the api is ", response);
  //   return response.data;
  // } catch (error) {
  //   console.log("error shown ", error);
  // }
};
