import React, { useEffect, useRef, useState} from 'react';
import { Title } from '../aiAds/AiAdsGenerator';
import { Col, notification, Row, Select } from 'antd';
import  backIcon  from "../../styles/icons/back.svg";
import { addDays  } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { enUS } from 'date-fns/locale';
import EngagementPieChart from "./components/EngagementPieChart";
import DualLineChart from "./components/DualLineChart";
import logo from '../../styles/images/facebooklogo.svg';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import companyLogo from '../../styles/images/logo.png';

/* global FB */
const Facebookgradel = () => {

  const [adAccounts, setAdAccounts] = useState([]);
  const [adCamp, setAdCamp] = useState();
  const [adAccountValue, setAdAccountValue] = useState();
  const { Option } = Select;
  const [adCampaigns, setAdCampaigns] = useState([]);
  const [owner_name, set_owner_name] = useState(null);
  const [adsDetailUi, setAdDetailsUi] = useState(false);
  const [login, setlogin] = useState(false);
  const [fullReport, setFullReport] = useState(false);
  const [insightsData, setInsightsData] = useState({});
  const [ctrPercentage, setCtrPercentage] = useState(0);
  const [age_data, setAgeData] = useState([]);
  const [leads, setLeads] = useState(0);
  const [avgCpl, setAvgCpl] = useState(0);
  const [fullReportSpend, setFullReportSpend] = useState([]);
  const [genderData, setGenderData] = useState([]);
  const [postReactions, setPostReactions] = useState('0');
  const [postLikes, setPostLikes] = useState('0');
  const [postSaves, setPostSaves] = useState('0');
  const [postComments, setPostComments] = useState('0');
  const [loading, setLoadingState] = useState(false);
  const reportRef = useRef();

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);
  const [showDatePicker, setShowDatePicker] = useState(false);

  // const accesstoken = "EAAaIrlhAFFkBO7BlywAcYpHImu2Mb3b89ERVaV3j7yLMWW153jHjwEdRZC4zibWtkdl0AaWQO1Dxx6l0z78expEFWNUiQnTdjGgX6mLfDXiN8VK1uniiWBUlBZCZB1h9Eq3e1g9xi2ZB6I67lV7UZCyN1D0ApUxgVdQWhI1yBkcjkzt1E3AIDExUzWlwkwuIZAdBhZAuLwpLpbPSgQrFgZDZD";
  useEffect(() => {
    const loadFBSDK = () => {
      window.fbAsyncInit = function () {
        FB.init({
          appId: '1839132246545497',
          cookie     : true,
          xfbml      : true,
          version    : 'v20.0'
        });

        FB.AppEvents.logPageView();   
        
        FB.getLoginStatus(function(response) {
          statusChangeCallback(response);
        });
        
        (function(d, s, id){
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {return;}
          js = d.createElement(s); js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
      };


      const script = document.createElement('script');
      script.async = true;
      script.defer = true;
      script.crossOrigin = 'anonymous';
      script.src = 'https://connect.facebook.net/en_US/sdk.js';
      document.body.appendChild(script);
    };
    
    const now = new Date();
    setState([{
      startDate: new Date(now.getFullYear(), now.getMonth(), 1),
      endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0),
      key: 'selection'
    }]);

    if (!window.FB) {
      loadFBSDK();
    } else {
      testAPI();
    }
    //  testAPI();
  }, []);

  const statusChangeCallback = (response) => {
    if (response.status === 'connected') {
      setlogin(true);
      testAPI();
      checkPermissions();
    } else {
      notification['error']({
        message: 'Please log into this webpage',
    });
    }
  };


  const checkPermissions = () => {
    FB.api('/me/permissions', function(response) {
      console.log('Permissions:', response);
    });
  };

  const checkLoginState = () => {
    FB.getLoginStatus(function(response) {
      statusChangeCallback(response);
    });
  };

  const testAPI = () => {
    FB.api('/me', 'GET',  function (response) {
      if (response.name) {
        set_owner_name(response.name);
      } else {
        notification['error']({
          message: 'Failed to retrieve user information.',
        });
      }
    });

    FB.api('/me/adaccounts', 'GET', {fields: 'id,name' }, function (response) {
      if (response && response.data && response.data.length > 0) {
        setAdAccounts(response.data);
      } else {
        notification['error']({
          message: 'No ads Account Found',
      });
      }
    });
  };

  const selectedAdAccount = (value) => {
    setAdAccountValue(value);
    fetchAdCampaigns(value);
  };

  const selectedCampaignValue = (value) => {
    setAdCamp(value);
  };
  
  const fetchAdCampaigns = (accountId) => {
    FB.api(`/${accountId}/campaigns`, 'GET', {fields: 'id,name' }, function (response) {
      if (response && response.data && Array.isArray(response.data) && response.data.length > 0) {
        setAdCampaigns(response.data);
      } else {
        setAdCampaigns([]);
      }
    });
  }

  const start_fetching_report = async (status) => {
    let value;
    if (status == 'Account') {
      if (adAccountValue !== null && adAccountValue != undefined) {
        value = adAccountValue;
      }
      else {
        notification['error']({
          message: 'Chose Account First',
      });
        return;
       }
    }
    else {
      if (adCamp !== null && adCamp != undefined) {
        value = adCamp;
      }
      else {
        notification['error']({
          message: 'Chose Campaign First',
      });
        return;
       }
    }

    const start = new Date(state[0].startDate);
    const end = new Date(state[0].endDate);
    const startDate = formatDateInFB(start);
    const endDate = formatDateInFB(end);

    setLoadingState(true);
    await fetch_account_details(value, startDate, endDate);
    await fetchLeadsByGender(value, startDate, endDate);
    await fetch_age_details(value,startDate, endDate);
    await fetch_full_EngagementData(value,startDate, endDate);
    setLoadingState(false);
  };


  const fetch_account_details = async (value,startDate,endDate) => { 
    if (value !== null && value != undefined) {
      FB.api(`/${value}/insights`, 'GET', {
        // access_token: accesstoken,
        fields: 'reach,ctr,clicks,cpm,impressions,inline_link_clicks,spend,actions.action_type(post_save,post_share),cost_per_action_type.action_type(post_save,post_share)',
        time_range: { since: startDate, until: endDate },
      },
        function (response) {
          if (response && response.data && response.data.length > 0) {
            console.log(response);
            setAdDetailsUi(true);
            const insights = response.data[0];
            setInsightsData(insights);

            // Convert CTR to percentage
            if (insights && insights.ctr) {
              setCtrPercentage(formatNumber(insights.ctr));
            }

            const leadAction = insights.actions ? insights.actions.find(action => action.action_type === 'lead') : null;
            const leadValue = leadAction ? leadAction.value : 0;
            setLeads(leadValue);

            const leadCpl = insights.cost_per_action_type ? insights.cost_per_action_type.find(cost_per_action_type => cost_per_action_type.action_type === 'lead') : null;
            const leadCplValue = leadCpl ? leadCpl.value : 0;
            setAvgCpl(leadCplValue);
          }
          else {
            notification['error']({
              message: 'No ads data Found',
            });
            setAdDetailsUi(false);
            setLoadingState(false);
            return;
          }
        });
    } else { 
      notification['error']({
        message: 'Select the Ad Account First',
    });
    }
  }

  const fetch_full_EngagementData = async (value,startDate, endDate) => {
    FB.api(`/${value}/insights`, 'GET', {
      // access_token : accesstoken,
      fields: 'actions.action_type(post_save,comment,like,post_reaction)',
      time_range: { since: startDate, until: endDate },
    },
    function (response) {
      if (response && response.data && response.data.length > 0) {
        const aggregatedData = response.data.flatMap(campaign => 
          campaign.actions
            .filter(action => ['post_reaction', 'onsite_conversion.post_save', 'comment', 'like'].includes(action.action_type))
            .map(action => ({
              action_type: action.action_type,
              value: action.value
            }))
        );
   
        setPostReactions(aggregatedData.find(item => item.action_type === 'post_reaction')?.value || '0');
        setPostLikes(aggregatedData.find(item => item.action_type === 'like')?.value || '0');
        setPostComments(aggregatedData.find(item => item.action_type === 'comment')?.value || '0');
        setPostSaves(aggregatedData.find(item => item.action_type === 'onsite_conversion.post_save')?.value || '0');
      
        fetch_account_camp_details(value,startDate, endDate);
      } else {
        console.error("No data found or error fetching data.");
      }
    });
  }

  
  const fetch_age_details = async (value,startDate, endDate) => {
    FB.api(`/${value}/insights`, 'GET', {
      // access_token: accesstoken,
      fields: 'clicks,inline_link_clicks',
      breakdowns: 'age',
      time_range: { since: startDate, until: endDate }
    },
      function (response) {
        if (response && response.data && response.data.length > 0) {
          const formattedData = response.data.map(item => ({
            clicks: item.clicks,
            link_clicks: item.inline_link_clicks,
            age: item.age
          }));

          setAgeData(formattedData);
          setAdDetailsUi(true);
        }
      });
  };
  
  const fetch_account_camp_details = async (value,startDate, endDate) => {
    FB.api(`/${value}/insights`, 'GET', {
      // access_token: accesstoken,
      fields: 'spend,actions',
      time_range: { since: startDate, until: endDate },
      time_increment: 1,  // Break down the data by day
    },
    function (response) {
      if (response && response.data && response.data.length > 0) {
          const formattedData = response.data.map(insight => {
          const spend = insight.spend || 0;
          
          // Find the lead action in the actions array
          const leadAction = insight.actions ? insight.actions.find(action => action.action_type === 'lead') : null;
          const leadValue = leadAction ? leadAction.value : 0;
  
          return {
            clicks: spend,
            link_clicks: leadValue,
            age: insight.date_start  // Add the date for daily breakdown
          };
        });
  
        // Store the formatted data in state
        setFullReportSpend(formattedData);
        setAdDetailsUi(true);
        setFullReport(true);
      } else {
        notification['error']({
          message: 'No Data Found',
      });
      }
    });
  };
  
  
  const fetchLeadsByGender = async (value,startDate, endDate) => {
    FB.api(`/${value}/insights`, 'GET', {
      // access_token: accesstoken,
      fields: 'actions',
      breakdowns: 'gender',
      time_range: { since: startDate, until: endDate }
    },
      function (response) {
      if (response && response.data && response.data.length > 0) {
        const formattedGenderData = response.data.map(item => {
          const leadAction = item.actions ? item.actions.find(action => action.action_type === 'lead') : null;
          const leadValue = leadAction ? parseInt(leadAction.value) : 0;
  
          return {
            gender: item.gender,
            leads: leadValue,
          };
        });
        setGenderData(formattedGenderData);
      }
    });
  };
  
  
  const pressedBack = () => { 
    setAdDetailsUi(false);
    setFullReport(false);
  }


  function formatNumber(value) {
    const number = parseFloat(value).toFixed(2);
    const parts = number.toString().split('.');
    const integerPart = parts[0];
    let decimalPart = parts[1];
    let formattedNumber = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (decimalPart) {
      formattedNumber += '.' + decimalPart;
    }
    return formattedNumber;
  }

  const handleSelect = (ranges) => {
    setState([ranges.selection]);
    setShowDatePicker(false);
  };

  const formatDateRange = (start, end) => {
    if (!start || !end) return '';
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return `${start.toLocaleDateString(undefined, options)} - ${end.toLocaleDateString(undefined, options)}`;
  };

  const formatDateInFB = (date) => {    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getBase64ImageFromUrl = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = (err) => {
        reject(err);
      };
    });
  };

  const downloadReport = async () => {
    const input = reportRef.current;
  
    // Get the actual full height of the container (including non-visible parts)
    const originalHeight = input.style.height;
    const originalWidth = input.style.width;
    const originalOverflow = input.style.overflow;
  
    // Set styles to ensure the entire content is captured
    input.style.height = `${input.scrollHeight}px`;
    input.style.width = 'auto';  
    input.style.overflow = 'visible';  
  
    // Convert the company logo to base64
    const base64Logo = await getBase64ImageFromUrl(companyLogo);
  
    const img = new Image();
    img.src = base64Logo;
  
    img.onload = () => {
      const logoAspectRatio = img.width / img.height;
  
      html2canvas(input, {
        scrollY: -window.scrollY,  // Capture from the top
        scale: 2,  // Adjust scale for better quality
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
  
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210;  
        const pageHeight = 297; 
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
  
        const logoWidth = 30;  
        const logoHeight = logoWidth / logoAspectRatio;  
  
        const text = '© 2024 Marketing With HMA. All Rights Reserved.';
        pdf.setFontSize(10);
  
        const startXPosition = 10;  // Align footer to the left
        const footerYPosition = pageHeight - 15;  // Footer position
        const headerYPosition = 15;  // Header position
  
        // Function to add header with logo and line
        const addHeader = () => {
          pdf.addImage(base64Logo, 'PNG', (imgWidth - logoWidth) / 2, headerYPosition - logoHeight, logoWidth, logoHeight);  // Center logo
          // pdf.setDrawColor(128, 128, 128); 
          // pdf.setLineWidth(0.5);  
          // pdf.line(7, headerYPosition + 10, 200, headerYPosition + 10);  // Gray line below the header
        };
  
        // Function to add footer on the last page only
        const addFooter = () => {
          pdf.setDrawColor(128, 128, 128); 
          pdf.setLineWidth(0.5);  
          pdf.line(7, footerYPosition - logoHeight - 7 - 5, 200, footerYPosition - logoHeight - 7 - 5);  // Gray line above footer
          pdf.addImage(base64Logo, 'PNG', startXPosition, footerYPosition - logoHeight + 3, logoWidth, logoHeight);  // Footer logo
          pdf.setTextColor(128, 128, 128);
          pdf.text(text, startXPosition + logoWidth + 5, footerYPosition);  // Footer text
        };
  
        // Add first page content
        pdf.addImage(imgData, 'PNG', 0, position + headerYPosition + 15, imgWidth, imgHeight);  // Adjust content position below header
        addHeader();
        heightLeft -= pageHeight;
  
        // Add additional pages if needed
        while (heightLeft > 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position + headerYPosition + 15, imgWidth, imgHeight);  // Adjust content position below header
          heightLeft -= pageHeight;
        }
  
        // Add footer only on the last page
        addFooter();
  
        // Restore the original styles
        input.style.height = originalHeight;
        input.style.width = originalWidth;
        input.style.overflow = originalOverflow;
  
        // Save the PDF
        pdf.save('report_with_footer_on_last_page.pdf');
      });
    };
  };
  
  

  
  return (
    <div ref={reportRef}>
      <div className='facebook-container'>
      {login ? (
      <div className='facebook-header' >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between',width:'100%',alignItems:'center',margin:'5px' }}>
          <div className='facebook-header-row'>
            <img src={backIcon} alt='back' onClick={pressedBack} style={{ cursor: "pointer" }} />
            <div className="date-picker-container" style={{color:"black",fontWeight:"600"}}>
                <div onClick={() => setShowDatePicker(!showDatePicker)} style={{ cursor: 'pointer', marginBottom: '10px' }}>
                  {formatDateRange(state[0].startDate, state[0].endDate)} {showDatePicker ? "▲" : "▼"}
                </div>
                {showDatePicker && (
                <div className="date-picker-dropdown">
                  <DateRangePicker
                    onChange={handleSelect}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={state}
                    direction="horizontal"
                    locale={enUS}
                  />
                </div>
              )}
            </div>
          </div>

          {adsDetailUi ? <button className='ant-btn ant-btn-default primary-button' onClick={downloadReport}>Download</button> :<></> }
            
        </div>
          {!fullReport ?
            (   <button 
              className='ant-btn ant-btn-default primary-button' 
              style={{margin:"15px"}} 
              onClick={() => start_fetching_report('Account')}
            >
              Account Report
            </button>
            ) : (<></>)}
      </div>
      ) : (<></>)
      }

      {loading && adsDetailUi ? (
        <div style={{ width: "100%", height: "100%", display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: 'column' }}>
          <svg xmlns="http://www.w3.org/2000/svg" height="200px" width="200px" viewBox="0 0 200 200" className="pencil">
            <defs>
              <clipPath id="pencil-eraser">
                <rect height="30" width="30" ry="5" rx="5"></rect>
              </clipPath>
            </defs>
            <circle transform="rotate(-113,100,100)" strokeLinecap="round" strokeDashoffset="439.82" strokeDasharray="439.82 439.82" strokeWidth="2" stroke="currentColor" fill="none" r="70" className="pencil__stroke"></circle>
            <g transform="translate(100,100)" className="pencil__rotate">
              <g fill="none">
                <circle transform="rotate(-90)" strokeDashoffset="402" strokeDasharray="402.12 402.12" strokeWidth="30" stroke="hsl(223,90%,50%)" r="64" className="pencil__body1"></circle>
                <circle transform="rotate(-90)" strokeDashoffset="465" strokeDasharray="464.96 464.96" strokeWidth="10" stroke="hsl(223,90%,60%)" r="74" className="pencil__body2"></circle>
                <circle transform="rotate(-90)" strokeDashoffset="339" strokeDasharray="339.29 339.29" strokeWidth="10" stroke="hsl(223,90%,40%)" r="54" className="pencil__body3"></circle>
              </g>
              <g transform="rotate(-90) translate(49,0)" className="pencil__eraser">
                <g className="pencil__eraser-skew">
                  <rect height="30" width="30" ry="5" rx="5" fill="hsl(223,90%,70%)"></rect>
                  <rect clipPath="url(#pencil-eraser)" height="30" width="5" fill="hsl(223,90%,60%)"></rect>
                  <rect height="20" width="30" fill="hsl(223,10%,90%)"></rect>
                  <rect height="20" width="15" fill="hsl(223,10%,70%)"></rect>
                  <rect height="20" width="5" fill="hsl(223,10%,80%)"></rect>
                  <rect height="2" width="30" y="6" fill="hsla(223,10%,10%,0.2)"></rect>
                  <rect height="2" width="30" y="13" fill="hsla(223,10%,10%,0.2)"></rect>
                </g>
              </g>
              <g transform="rotate(-90) translate(49,-30)" className="pencil__point">
                <polygon points="15 0,30 30,0 30" fill="hsl(33,90%,70%)"></polygon>
                <polygon points="15 0,6 30,0 30" fill="hsl(33,90%,50%)"></polygon>
                <polygon points="15 0,20 10,10 10" fill="hsl(223,10%,10%)"></polygon>
              </g>
            </g>
          </svg>

          <p style={{ color: '#4BB543', fontWeight: '700', textAlign: 'center', marginTop: '30px' }}>
            😊  Please wait! While we are drawing the charts for you  😊
          </p>
        </div>
      ) : (
        <Row align='top' justify='top' style={{ width: "95%" , marginBottom:'10%'}}>
        {adsDetailUi ? (
          <Col span={24} justify='center' align='center'>
             {/* First Term */}
            <Title style={{ textAlign: 'center',fontSize:'25px'  }}>Facebook Ads Lead Gen Performance</Title>
            <div className='facebook-background-container' style={{width:'85%'}}>
              <div className='facebook-plane-column'>
                <div className='facebook-value-card-container'>
                  <div className='facebook-logo-container'>
                    <img style={{ width: '24px', height: '24px' }} src={logo} alt='fb' />
                    <div className='facebook-logo-column-container'>
                       <p style={{fontWeight:"600", textAlign:'center',width:'100%'}}>Amount Spent</p>
                       <h2 style={{color:"black",fontSize:"16px",paddingTop:"20px",fontWeight:"600"}}>₹ { formatNumber(insightsData.spend)}</h2>
                    </div>
                  </div>
                </div>

                <div className='facebook-value-card-container'>
                  <div className='facebook-logo-container'>
                    <img style={{ width: '24px', height: '24px' }} src={logo} alt='fb' />
                    <div className='facebook-logo-column-container'>
                      <p style={{ fontWeight: "600", textAlign: 'center', width: '100%' }}>CPL</p>
                      <h2 style={{color:"black",fontSize:"16px",paddingTop:"20px",fontWeight:"600"}}>₹ {formatNumber(avgCpl)}</h2>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className='facebook-plane-column' style={{justifyContent:'center',alignItems:'center'}}>
                <div className='facebook-value-card-container'>
                   <img style={{ width: '40px', height: '40px' , marginTop:'10px' }} src={logo} alt='fb' />
                   <p style={{fontWeight:"600"}}>Leads</p>
                   <h2 style={{color:"black",fontSize:"16px",paddingTop:"20px",fontWeight:"600"}}>{ formatNumber(leads).replace('.00', '')}</h2>
                </div>
              </div>

              
              <div className='facebook-plane-column'>
                <div className='facebook-value-card-container'>
                  <div className='facebook-logo-container'>
                    <img style={{ width: '24px', height: '24px' }} src={logo} alt='fb' />
                    <div className='facebook-logo-column-container'>
                      <p style={{ fontWeight: "600", textAlign: 'center', width: '100%' }}>CPM</p>
                      <h2 style={{color:"black",fontSize:"16px",paddingTop:"20px",fontWeight:"600"}}>₹  {formatNumber(insightsData.cpm)}</h2>
                    </div>
                  </div>
                </div>

                <div className='facebook-value-card-container'>
                  <div className='facebook-logo-container'>
                      <img style={{ width: '24px', height: '24px' }} src={logo} alt='fb' />
                    <div className='facebook-logo-column-container'>
                      <p style={{ fontWeight: "600", textAlign: 'center', width: '100%' }}>CTR</p>
                      <h2 style={{color:"black",fontSize:"16px",paddingTop:"20px",fontWeight:"600"}}> {ctrPercentage} %</h2>
                    </div>
                  </div> 
                </div>
              </div>
            </div>

            {/* First Chart */}
            <div className='facebook-value-card-container' style={{ width: '81%' }}>
            <div className='facebook-logo-container'>
                  <img style={{ width: '24px', height: '24px' }} src={logo} alt='fb' />
                  <p style={{ fontWeight: "600", textAlign: 'center', width: '100%' }}>Amount spent and Leads by Days</p>
            </div>  
                <DualLineChart data={fullReportSpend} type={"Facebook"} cat1={"Amount Spent"} cat2={"Leads"} Xval={"Days"} Yval={"Spend and Leads"}/>  
            </div>

            {/* Second Term */}
            <Title style={{ textAlign: 'center', marginTop:'40px', marginBottom:'20px',fontSize:'25px' }}>Lead Analytics Overview</Title>
            <div className='facebook-plane-column' style={{width:'85%'}}>
               <div className='facebook-background-container'>
                <div className='facebook-value-card-container'>
                  <div className='facebook-logo-container'>
                    <img style={{ width: '24px', height: '24px' }} src={logo} alt='fb' />
                    <div className='facebook-logo-column-container'>
                       <p style={{fontWeight:"600", textAlign:'center',width:'100%'}}>Impressions</p>
                       <h2 style={{color:"black",fontSize:"16px",paddingTop:"20px",fontWeight:"600"}}> { formatNumber(insightsData.impressions).replace('.00', '')}</h2>
                    </div>
                  </div>
                </div>

                <div className='facebook-value-card-container'>
                  <div className='facebook-logo-container'>
                    <img style={{ width: '24px', height: '24px' }} src={logo} alt='fb' />
                    <div className='facebook-logo-column-container'>
                      <p style={{ fontWeight: "600", textAlign: 'center', width: '100%' }}>Reach</p>
                      <h2 style={{color:"black",fontSize:"16px",paddingTop:"20px",fontWeight:"600"}}> {formatNumber(insightsData.reach).replace('.00', '')}</h2>
                    </div>
                  </div>
                </div>
              
                <div className='facebook-value-card-container'>
                  <div className='facebook-logo-container'>
                    <img style={{ width: '24px', height: '24px' }} src={logo} alt='fb' />
                    <div className='facebook-logo-column-container'>
                      <p style={{ fontWeight: "600", textAlign: 'center', width: '100%' }}>Clicks</p>
                      <h2 style={{color:"black",fontSize:"16px",paddingTop:"20px",fontWeight:"600"}}> {formatNumber(insightsData.clicks).replace('.00', '')}</h2>
                    </div>
                  </div>
                </div>
               
                <div className='facebook-value-card-container'>
                  <div className='facebook-logo-container'>
                    <img style={{ width: '24px', height: '24px' }} src={logo} alt='fb' />
                    <div className='facebook-logo-column-container'>
                      <p style={{ fontWeight: "600", textAlign: 'center', width: '100%' }}>Link Clicks</p>
                      <h2 style={{color:"black",fontSize:"16px",paddingTop:"20px",fontWeight:"600"}}> {formatNumber(insightsData.inline_link_clicks).replace('.00', '')}</h2>
                    </div>
                  </div>
                </div>
               </div>
               
              
              {/* Second Chart */}
              <div className='facebook-background-container'>
                 <div className='facebook-value-card-container' style={{ width: '81%' }}>
                    <div className='facebook-logo-container'>
                      <img style={{ width: '24px', height: '24px' }} src={logo} alt='fb' />
                      <p style={{ fontWeight: "600", textAlign: 'center', width: '100%' }}>Clicks and Link clicks by Age</p>
                    </div>  
                    <DualLineChart data={age_data} type={"Facebook"}  cat1={"Click"} cat2={"Link Clicks"} Xval={"Age"} Yval={"Clicks and Link Clicks"}/>  
                 </div>
                
                <div className='facebook-value-card-container' style={{ width: '81%' }}>
                    <div className='facebook-logo-container'>
                      <img style={{ width: '24px', height: '24px' }} src={logo} alt='fb' />
                      <p style={{ fontWeight: "600", textAlign: 'center', width: '100%' }}>Leads by Gender</p>
                    </div>  
                    <EngagementPieChart data={genderData} />
                 </div>
              </div>
            </div>

            {/* Third Term */}
            <Title style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px', fontSize: '25px' }}>Post Engagement</Title>
            <div className='facebook-background-container' style={{marginBottom:'150px'}}>
                <div className='facebook-value-card-container'>
                  <div className='facebook-logo-container'>
                    <img style={{ width: '24px', height: '24px' }} src={logo} alt='fb' />
                    <div className='facebook-logo-column-container'>
                       <p style={{fontWeight:"600", textAlign:'center',width:'100%'}}>Post Reactions</p>
                       <h2 style={{color:"black",fontSize:"16px",paddingTop:"20px",fontWeight:"600"}}> { postReactions }</h2>
                    </div>
                  </div>
                </div>

                <div className='facebook-value-card-container'>
                  <div className='facebook-logo-container'>
                    <img style={{ width: '24px', height: '24px' }} src={logo} alt='fb' />
                    <div className='facebook-logo-column-container'>
                      <p style={{ fontWeight: "600", textAlign: 'center', width: '100%' }}>Post Likes</p>
                      <h2 style={{color:"black",fontSize:"16px",paddingTop:"20px",fontWeight:"600"}}> {postLikes}</h2>
                    </div>
                  </div>
                </div>
              
                <div className='facebook-value-card-container'>
                  <div className='facebook-logo-container'>
                    <img style={{ width: '24px', height: '24px' }} src={logo} alt='fb' />
                    <div className='facebook-logo-column-container'>
                      <p style={{ fontWeight: "600", textAlign: 'center', width: '100%' }}>Post Save</p>
                      <h2 style={{color:"black",fontSize:"16px",paddingTop:"20px",fontWeight:"600"}}> {postSaves}</h2>
                    </div>
                  </div>
                </div>
               
                <div className='facebook-value-card-container'>
                  <div className='facebook-logo-container'>
                    <img style={{ width: '24px', height: '24px' }} src={logo} alt='fb' />
                    <div className='facebook-logo-column-container'>
                      <p style={{ fontWeight: "600", textAlign: 'center', width: '100%' }}>Post Comments</p>
                      <h2 style={{color:"black",fontSize:"16px",paddingTop:"20px",fontWeight:"600"}}> {postComments}</h2>
                    </div>
                  </div>
                </div>
            </div>
        </Col>
        ) : (  
          !login ? (
              <Col span={24} justify='center' align='middle'>
                
              <Title style={{ marginBottom: 30,marginTop:"50px" }}>Login With Facebook to check your AddScore</Title>
                <div style={{ marginBottom: '80px' }}>
                </div>
                <div 
      className="fb-login-button" 
      data-width="" 
      data-size="large" 
      data-button-type="continue_with" 
      data-layout="default" 
      data-auto-logout-link="false" 
      data-use-continue-as="false" 
      data-scope="ads_read" 
      data-config-id="1720609635360111"
      onClick={checkLoginState}
                  
    />
      <div id="status"></div>
            </Col>
            ) : (
            <Col span={24} justify='center' align='middle'>
              <Title>Check Your Ad Report in One Click {owner_name}</Title>
              <div style={{ marginBottom: '20px' }}>
                <h2 style={{ color: "#0d6efd", fontSize: "18px", marginBottom: '20px', marginTop: "30px" }}>Select your Ad Account</h2>
                <Select 
                  placeholder="Select Ad Account"
                  style={{ width: '80%' }}
                  onChange={selectedAdAccount}>
                  {adAccounts.map(accounts => (
                    <Option key={accounts.id} value={accounts.id}>{accounts.name} ({accounts.id})</Option>
                  ))}
                </Select>
              
                <h2 style={{ color: "#0d6efd", fontSize: "18px", marginTop: "40px" }}>Select your Ad Campaigns</h2>
                <p style={{ fontSize:"12px" }}>This Campaigns from your account which you select Previously</p>
    
                <Select 
                  placeholder="Select Campaign"
                  style={{ marginTop: "30px", marginBottom: "20px", width: "80%" }}
                  onChange={selectedCampaignValue}>
                  {adCampaigns.map(campaigns => (
                    <Option key={campaigns.id} value={campaigns.id}>{campaigns.name}</Option>
                  ))}
                </Select>
              </div>
              <button className='ant-btn ant-btn-default primary-button' onClick={() => start_fetching_report('Report')}>Get Report</button>
            </Col>
          )
        )}
      </Row>
      )
      }
      
      </div>
    </div>
  );
};

export default Facebookgradel;
