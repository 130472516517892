import { CaretDownFilled, StarFilled, StarOutlined } from "@ant-design/icons";
import { Button as AntButton, Popover, Row, Tag, notification } from "antd";
import { Timestamp, addDoc, collection } from "firebase/firestore";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Sparklines, SparklinesBars } from "react-sparklines";
import {
  GOOGLE_KEYWORDS_API_DATA_LIMIT,
  PAGE_HEAD_TITLE,
  YOUTUBE_KEYWORDS_API_DATA_LIMIT,
} from "../../constants";
import AppContext from "../../context/AppContext";
import { Database } from "../../firebase";
import BunnyPlayer from "../BunnyPlayer";
import CollectionModal from "./collectionModal";

import { getKeywordResearch } from "../../services/keywordLabsApiService";
import {
  checkQuotaValidation,
  updateRequestUserQuota,
} from "../../services/quotaCheckService";
import UrlLinks from "../../styles/icons/redirect.png";
import { Button } from "../../styles/styles";
import { humanReadableNumber, pleaseSubscribe } from "../../utils/Helpers";
import LocationDropdown, { locationOptions } from "../locationDropdown";
import KwChartAnt from "./kwChartAnt";
import "./style.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export const getKeywordCompetitionTag = (keywordCompetition) => {
  keywordCompetition = keywordCompetition ? keywordCompetition : 1;
  keywordCompetition = Math.round(keywordCompetition);
  let tagElement;
  if (keywordCompetition <= 14) {
    tagElement = <Tag color="red">Very easy</Tag>;
  } else if (keywordCompetition <= 29) {
    tagElement = <Tag color="orange">Easy</Tag>;
  } else if (keywordCompetition <= 49) {
    tagElement = <Tag color="green">Average</Tag>;
  } else if (keywordCompetition <= 69) {
    tagElement = <Tag color="cyan">Hard</Tag>;
  } else if (keywordCompetition <= 84) {
    tagElement = <Tag color="geekblue">Very Hard</Tag>;
  } else {
    tagElement = <Tag color="purple">Extreme</Tag>;
  }

  return (
    <React.Fragment>
      {keywordCompetition > 9 ? keywordCompetition : "0" + keywordCompetition}
      &nbsp;&nbsp;{tagElement}
    </React.Fragment>
  );
};

const KeywordSearch = (props) => {
  const { setLoadingFullScreen } = useContext(AppContext);

  const searchType = props.searchType;
  const authUser = props.user;
  const { uid, accountType } = authUser;
  let keywordApiDataLimit;

  if (searchType === "RELATED_KEYWORDS") {
    keywordApiDataLimit = GOOGLE_KEYWORDS_API_DATA_LIMIT;
  } else {
    keywordApiDataLimit = YOUTUBE_KEYWORDS_API_DATA_LIMIT;
  }

  const [openModal, setOpenModal] = useState(false);
  const [inputKey, setInputKey] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [favouriteCount, setFavouriteCount] = useState(0);

  const [flag, setflag] = useState(false);
  const [flag2, setflag2] = useState(false);
  // const [loading, setLoadingFullScreen] = useState(false);
  const [locationData, setLocationData] = useState(locationOptions[6]);
  const [currentKeyWord, setCurrentKeyWord] = useState(null);

  useEffect(() => {
    document.title = `${
      searchType.toLowerCase().charAt(0).toUpperCase() +
      searchType.toLowerCase().slice(1).replace("_", " ")
    } ${PAGE_HEAD_TITLE}`;
  }, [searchType]); //eslint-disable-line

  useEffect(() => {
    // reset to default state. If searchType changes
    setInputKey("");
    setOpenModal(false);
    setSearchData([]);
    setFavouriteCount(0);
    setflag(false);
    setflag2(false);
    setLoadingFullScreen(false);
    setCurrentKeyWord(null);
  }, [searchType]); //eslint-disable-line

  useEffect(() => {
    const starred = searchData.filter((item) => item.isFav).length;
    setFavouriteCount(starred);
    const matchedKey = searchData.find(
      (items) => items.keyword.toLowerCase() === inputKey.toLowerCase()
    );
    setCurrentKeyWord(matchedKey);
  }, [searchData]); // eslint-disable-line

  const openClickModal = () => {
    if (favouriteCount) {
      setOpenModal(true);
    } else {
      notification["error"]({ message: "Please select keywords to save!" });
    }
  };

  const closeClickModal = () => {
    setOpenModal(false);
  };

  const handleOnSelect = (item, isSelect) => {
    let updatedItem = [...searchData];
    updatedItem.map((element) => {
      if (element.keyword === item.keyword) {
        if (isSelect) {
          element.isFav = true;
        } else {
          element.isFav = false;
        }
      }
      return element;
    });
    setSearchData(updatedItem);
  };

  const handleOnSelectAll = (isSelect) => {
    if (isSelect) {
      let updatedItem = searchData.map((element) => {
        element.isFav = true;
        return element;
      });
      setSearchData(updatedItem);
    } else {
      let updatedItem = searchData.map((element) => {
        element.isFav = false;
        return element;
      });
      setSearchData(updatedItem);
    }
  };

  const handleSaveCollection = async (collectionName) => {
    setLoadingFullScreen(true);

    const newData = searchData
      .filter((item) => item.isFav)
      .map((item) => {
        delete item.isFav;
        return item;
      });

    const dataString = JSON.stringify(newData);
    const payload = {
      userId: uid,
      collectionName: collectionName,
      searchType: searchType.replace("_", " "),
      createdDate: Timestamp.fromDate(moment().toDate()),
      keywords: dataString,
      type: "KEYWORD_SEARCH",
    };

    const docRef = await addDoc(
      collection(Database, "keywordSearchCollection"),
      payload
    );

    // console.log("Document written with ID: ", docRef.id);
    if (docRef.id) {
      props.history.push("/keywords-search-collection");
    } else {
      alert("SOMETHING WENT WRONG");
    }
    setLoadingFullScreen(false);
  };

  const findKeywords = async (type) => {
    if (!inputKey) {
      notification["error"]({ message: "Please enter search keyword!" });
    } else {
      setLoadingFullScreen(true);

      const checkResponse = await checkQuotaValidation(
        "KEYWORD_LABS",
        authUser,
        {
          searchType,
        }
      );

      // // console.log("checkResponse:", checkResponse)

      if (checkResponse.valid === false) {
        setLoadingFullScreen(false);
        // console.log("NO ACCESS:", checkResponse.message)
      } else {
        let offset = 0;
        if (type === "loadMore") {
          offset = searchData.length;
        }
        const dts = {
          keyword: inputKey,
          location_code: locationData.key,
          currency_code: locationData.currencyCode,
          currency_symbol: locationData.currencySymbol,
          limit: keywordApiDataLimit,
          offset: offset,
        };
        const data = await getKeywordResearch(dts, searchType);

        // console.log("data:", data)
        if (data.length) {
          if (type === "search") {
            setSearchData(data);
          }

          if (type === "loadMore") {
            setSearchData([...searchData, ...data]);
          }
        } else {
          console.log("No records found");
          // alert("No records found");
          if (type !== "loadMore") {
            setSearchData([]);
            setCurrentKeyWord(null);
            notification["error"]({
              message: "No results!!",
              placement: "topRight",
            });
          } else {
            notification["error"]({ message: "No more records found!" });
          }
        }

        setLoadingFullScreen(false);
        // UPDATE USER QUOTA
        if (data.length > 0) {
          updateRequestUserQuota("KEYWORD_LABS", authUser, {
            searchType,
            numResults: data.length,
          });
        }
      }
    }
  };

  const sortDataOnTheBasisOfMonVol = () => {
    if (searchData.length && !flag) {
      setSearchData(searchData.sort((a, b) => a.searchVolume - b.searchVolume));
    } else {
      setSearchData(searchData.reverse());
    }
    setflag(!flag);
  };

  const sortDataOnTheBasisOfCpc = () => {
    if (searchData.length && !flag2) {
      setSearchData(
        searchData.sort((a, b) => a.cpc.substring(2) - b.cpc.substring(2))
      );
    } else {
      setSearchData(searchData.reverse());
    }
    setflag2(!flag2);
  };

  return (
    <React.Fragment>
      {openModal && favouriteCount ? (
        <CollectionModal
          open={openModal}
          searchType={searchType}
          closeClickModal={closeClickModal}
          onSubmit={handleSaveCollection}
        />
      ) : (
        ""
      )}
      {/* <Spin spinning={loading} tip="Loading..." size="large"> */}
      <div className="content-wrapper">
        <BunnyPlayer type={searchType} />

        <LocationDropdown
          defaultValue={locationData}
          onChange={(value) => {
            setLocationData(value);
          }}
        />

        <Row className="searchfbInterest">
          <div className="search-input-container">
            <input
              type="text"
              className="text-input"
              placeholder="Enter keyword"
              name="search"
              value={inputKey}
              onChange={(e) => setInputKey(e.target.value)}
            />
            {/* {
                            accountType === "FREE" ?
                                <Popover content={pleaseSubscribe} className="">
                                    <button type="submit" className="primary-button search-button not-allowed-button">Search</button>
                                </Popover>
                                : */}
            <button
              type="submit"
              className="primary-button search-button"
              onClick={() => findKeywords("search")}
            >
              Search
            </button>
            <button className=" view-my-collection-button">
              <Link to="/keywords-search-collection">View My Collection</Link>
            </button>
            {/* } */}
          </div>
        </Row>
        {currentKeyWord ? (
          <React.Fragment>
            <Row className="_target_keyword_result">
              <div>
                <h4>Target Keyword Results : {currentKeyWord.keyword}</h4>
              </div>

              <div className="_target_keyword_header">
                <div>
                  <h6>~ Monthly Search Volume</h6>
                  <p>{humanReadableNumber(currentKeyWord.searchVolume)}</p>
                </div>
                <div>
                  <h6>~ Paid Competition</h6>
                  {/* <p>{currentKeyWord.competition}</p> */}
                  <p>{getKeywordCompetitionTag(currentKeyWord.competition)}</p>
                </div>
                <div>
                  <h6>~ CPC</h6>
                  <p>{currentKeyWord.cpc}</p>
                </div>
              </div>
            </Row>
            <div className="antd-chart-cust-1">
              <KwChartAnt
                monthlySearchesData={currentKeyWord.monthlySearchesData}
              />
            </div>
          </React.Fragment>
        ) : null}

        {searchData.length ? (
          <React.Fragment>
            <Row className="save_collection_keyword_search">
              {accountType === "FREE" ? (
                <Popover content={pleaseSubscribe} className="">
                  <button
                    type="submit"
                    className="primary-button mt-2rem search-button not-allowed-button"
                  >
                    Save
                  </button>
                </Popover>
              ) : (
                <Button
                  className="primary-button mt-2rem"
                  onClick={openClickModal}
                >
                  Save
                </Button>
              )}
            </Row>
          </React.Fragment>
        ) : null}
        <Row className="fb_interest_list_page">
          {searchData.length ? (
            <div className="fb_hidden_list display-table">
              <div
                className="table-tr ant-list-header"
                style={{ backgroundColor: "#175bcb" }}
              >
                <div
                  className="table-td"
                  style={{ width: "50px", textAlign: "center" }}
                >
                  {searchData.length === favouriteCount ? (
                    <StarFilled
                      style={{ color: "#e7ec0e" }}
                      onClick={(e) => handleOnSelectAll(false)}
                    />
                  ) : (
                    <StarOutlined
                      style={{ color: "#ffffff" }}
                      onClick={(e) => handleOnSelectAll(true)}
                    />
                  )}
                </div>
                <div className="table-td">
                  {searchType === "KEYWORD_SUGGESTIONS"
                    ? "Keyword Suggestions"
                    : "Related Keywords"}
                </div>
                <div className="table-td" style={{ width: "180px" }}>
                  Average Monthly Volume
                  <CaretDownFilled onClick={sortDataOnTheBasisOfMonVol} />
                </div>
                <div className="table-td">
                  CPC
                  <CaretDownFilled onClick={sortDataOnTheBasisOfCpc} />
                </div>
                <div className="table-td">Competition</div>
                <div className="table-td">
                  Trends
                  <br />
                  {"(Latest <- Oldest)"}
                </div>
                <div className="table-td">See on YT</div>
              </div>

              {searchData.map((item, index) => {
                return (
                  <div key={index} className="table-tr">
                    <div
                      className="table-td"
                      style={{ width: "50px", textAlign: "center" }}
                    >
                      {item.isFav ? (
                        <StarFilled
                          style={{ color: "#e7ec0e" }}
                          onClick={(e) => handleOnSelect(item, false)}
                        />
                      ) : (
                        <StarOutlined
                          style={{ color: "#175BCB" }}
                          onClick={(e) => handleOnSelect(item, true)}
                        />
                      )}
                    </div>
                    <div className="table-td">{item.keyword}</div>
                    <div className="table-td">
                      {humanReadableNumber(item.searchVolume)}
                    </div>
                    <div className="table-td">{item.cpc}</div>
                    <div className="table-td">
                      {getKeywordCompetitionTag(item.competition)}
                    </div>
                    <div className="table-td">
                      <div style={{ width: "100px" }}>
                        <Sparklines
                          width={100}
                          height={25}
                          data={item.monthlySearches}
                        >
                          <SparklinesBars
                            style={{ fill: "#00bdcc", fillOpacity: ".5" }}
                          />
                        </Sparklines>
                      </div>
                    </div>
                    <div className="table-td">
                      <a
                        style={{ textAlign: "center", display: "block" }}
                        href={`https://www.youtube.com/results?search_query=${item.keyword}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={UrlLinks} alt="#" className="youtube_ref" />
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </Row>
        {searchData.length ? (
          <div className="pagination-wrapper">
            <AntButton
              className="ml-10"
              onClick={() => findKeywords("loadMore")}
            >
              Load more results..
            </AntButton>
          </div>
        ) : null}
      </div>
      {/* </Spin> */}
    </React.Fragment>
  );
};

export default withRouter(KeywordSearch);
