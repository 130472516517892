import React, { useState, useEffect } from "react";
import {
  StarFilled,
  StarOutlined,
  EditOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { Button, Input, notification, Row, List, Col, Popover } from "antd";
import styled from "styled-components";
import moment from "moment";
import {
  checkQuotaValidation,
  updateRequestUserQuota,
} from "../../services/quotaCheckService";
import { aiGeneratorService } from "../../services/aiApiService";
import { Database } from "../../firebase";
import BunnyPlayer from "../BunnyPlayer";
import CollectionModal from "../collectionModal/collectionModal";
import Google from "../../styles/icons/google.png";
import Facebook from "../../styles/icons/facebook.png";
import { PAGE_HEAD_TITLE } from "../../constants";
import { pleaseSubscribe } from "../../utils/Helpers";

const { TextArea } = Input;

export const Title = styled.div`
  font-size: 23px;
  color: #4885ed;
  font-weight: 600;
`;
export const Text = styled.span`
  font-size: 16px;
  color: #979797;
  font-weight: 600;
  margin-left: 10px;
  cursor: pointer;
  user-select: none;
`;
export const FormGroup = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const SolidButton = styled.button`
  background-color: #0057ff; /* Blue color */
  color: white;
  border: none;
  width: 50%;
  padding: 10px 0;
  border-radius: 4px;
  font-size: 16px;

  &:hover {
    background-color: #0046cc; /* Slightly darker blue on hover */
    cursor: pointer;
  }

  &:disabled {
    background-color: #cce0ff; /* Disabled state */
    color: #ffffff;
    cursor: not-allowed;
  }
`;

const OutlinedButton = styled.button`
  background-color: white;
  color: #0057ff;
  border: 2px solid #0057ff;
  width: 50%;
  padding: 8px 0;
  border-radius: 4px;
  font-size: 16px;

  &:hover {
    background-color: #f2f7ff;
    color: #0046cc;
    border-color: #0046cc;
    cursor: pointer;
  }

  &:disabled {
    border-color: #cce0ff;
    color: #cce0ff;
    cursor: not-allowed;
  }
`;

const AiAdsGenerator = (props) => {
  const searchType = props.searchType;
  const authUser = props.user;
  const { uid, accountType } = authUser;

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [productName, setProductName] = useState("Zara");
  const [productDescription, setProductDescription] = useState(
    "A Spanish apparel retailer. Specializes in fast fashion, and products include clothing, accessories, shoes, swimwear, beauty, and perfumes."
  );
  // const [choice, setChoice] = useState("");
  const [searchData, setSearchData] = useState([]);
  // const [searchData, setSearchData] = useState([
  //     { adText: 'hello 1' },
  //     { adText: 'hello 2' },
  //     { adText: 'hello 3' },
  // ]);
  const [favouriteCount, setFavouriteCount] = useState(0);
  const [isEditable, setIsEditable] = useState({});

  useEffect(() => {
    document.title = `${
      searchType.toLowerCase().charAt(0).toUpperCase() +
      searchType.toLowerCase().slice(1)
    } AI Ads Generator  ${PAGE_HEAD_TITLE}`;
  }, []); //eslint-disable-line

  useEffect(() => {
    setOpenModal(false);
    setSearchData([]);
    setProductName("");
    setProductDescription("");
    setFavouriteCount(0);
    setLoading(false);
    setIsEditable({});
  }, [searchType]);

  useEffect(() => {
    const starred = searchData.filter((item) => item.isFav).length;
    setFavouriteCount(starred);
  }, [searchData]);

  const openClickModal = () => {
    if (favouriteCount) {
      setOpenModal(true);
    } else {
      notification["error"]({
        message: "Please select favourites first",
        placement: "topRight",
      });
    }
  };

  const closeClickModal = () => {
    setOpenModal(false);
  };

  const generateResponse = async () => {
    if (!productName || !productDescription) {
      notification["error"]({
        message: "Please enter details",
        placement: "topRight",
      });
    } else {
      setLoading(true);

      let choice = "";

      if (searchType === "FACEBOOK") {
        choice = "Simple Facebook Ad";
      } else if (searchType === "FACEBOOK_ADVANCED") {
        choice = "Advanced Facebook Ad";
      } else if (searchType === "FACEBOOK_HEADLINE") {
        choice = "Facebook Headlines";
      }
      let searchTypeGeneral = searchType;
      if (
        searchType === "FACEBOOK" ||
        searchType === "FACEBOOK_ADVANCED" ||
        searchType === "FACEBOOK_HEADLINE"
      ) {
        searchTypeGeneral = "facebook";
      }

      const checkResponse = await checkQuotaValidation("AI_REQUEST", authUser, {
        searchTypeGeneral: searchTypeGeneral,
        prompt: productDescription,
      });

      if (checkResponse.valid === false) {
        setLoading(false);
      } else {
        const dataAI = await aiGeneratorService({
          productName,
          productDescription,
          searchTypeGeneral,
          choice,
        });

        if (dataAI) {
          notification["success"]({
            message: "Success!!",
            placement: "topRight",
          });
          const searchedData = [...searchData];
          setSearchData([...searchedData, { adText: `${dataAI}` }]);
        } else {
          notification["error"]({
            message: "No results!!",
            placement: "topRight",
          });
        }
        setLoading(false);

        // UPDATE USER QUOTA
        updateRequestUserQuota("AI_REQUEST", authUser, {
          searchTypeGeneral: searchTypeGeneral,
          prompt: productDescription,
        });
      }
    }
  };

  const handleOnSelect = (item, isSelect) => {
    const searchedData = [...searchData];
    searchedData.map((element) => {
      if (isSelect && element.adText === item.adText) {
        element.isFav = true;
      }
      if (!isSelect && element.adText === item.adText) {
        element.isFav = false;
      }
      return element;
    });
    setSearchData(searchedData);
  };

  const handleOnSelectAll = (isSelect) => {
    if (isSelect) {
      let updatedItem = searchData.map((element) => {
        element.isFav = true;
        return element;
      });
      setSearchData(updatedItem);
    } else {
      let updatedItem = searchData.map((element) => {
        element.isFav = false;
        return element;
      });
      setSearchData(updatedItem);
    }
  };

  const handleSaveCollection = async (collectionName) => {
    const newData = searchData
      .filter((item) => item.isFav)
      .map((item) => {
        delete item.isFav;
        return item;
      });

    if (newData.length === 0) {
      notification["error"]({
        message: "Please select favourites first",
        placement: "topRight",
      });
    } else {
      setLoading(true);

      const dataString = JSON.stringify(newData);

      const docRef = await addDoc(collection(Database, "aiAdsCollection"), {
        userId: uid,
        collectionName: collectionName,
        searchType: searchType,
        createdDate: Timestamp.fromDate(moment().toDate()),
        data: dataString,
      });

      if (docRef.id) {
        props.history.push("/ai-ads-collection");
      } else {
        alert("SOMETHING WENT WRONG");
      }
      setLoading(false);
    }
  };

  const handleTextChange = (value, idx) => {
    const searchedData = [...searchData];
    searchedData.map((element, index) => {
      if (index === idx) {
        element.adText = value;
      }
      return element;
    });
    setSearchData(searchedData);
  };

  const makeEditable = (flag, idx) => {
    const upEditable = { ...isEditable };
    upEditable[idx] = flag;
    setIsEditable(upEditable);
  };

  return (
    <div className="content-wrapper">
      {openModal && favouriteCount ? (
        <CollectionModal
          open={openModal}
          searchType={searchType}
          closeClickModal={closeClickModal}
          onSubmit={handleSaveCollection}
        />
      ) : (
        ""
      )}

      <div style={{ marginBottom: "2rem" }}>
        <BunnyPlayer type={searchType} />
      </div>

      <div className="white-box-wrapper">
        <div className="d-flex align-items-center">
          {searchType === "GOOGLE" ? (
            <React.Fragment>
              <img src={Google} alt={`Google`} width="43" height="43" />
              <Title>Google Ad Copies</Title>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <img src={Facebook} alt={`Facebook`} width="43" height="43" />
              <Title>Facebook Ad Copies</Title>
            </React.Fragment>
          )}
        </div>
        <div className="">
          <div className="form-group">
            <label>Brand/Product Name</label>
            <Input
              className="box-shadow-cust-1 mt-2"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Brand/Product Description</label>
            <TextArea
              className="box-shadow-cust-1 mt-2"
              value={productDescription}
              rows={6}
              // autoSize={{ minRows: 5, maxRows: 6 }}
              // showCount
              maxLength={400}
              onChange={(e) => setProductDescription(e.target.value)}
            />
          </div>
          <div className="char-counter">
            {productDescription.length}/400 Characters
          </div>
          {/* buttons  */}
          <div className="form-group text-center">
            {/* {
                            accountType === "FREE" ?
                                <Popover content={pleaseSubscribe} className="">
                                    <Button className="primary-button not-allowed-button">Generate</Button>
                                </Popover>
                                : */}
            {/* for generate ads  */}
            {/* <Button
              className="primary-button"
              onClick={generateResponse}
              disabled={loading}
            >
              {loading ? "Generating..." : "Generate"}
            </Button> */}

            {/* for generate imgs  */}
            {/* <Button className="primary-button">
              {loading ? "Generating..." : "Generate Image"}
            </Button> */}
            <FormGroup>
              {/* Solid "Generate" Button */}
              <SolidButton onClick={generateResponse} disabled={loading}>
                {loading ? "Generating..." : "Generate"}
              </SolidButton>

              {/* Outlined "Generate Image" Button */}
              <OutlinedButton>Generate Image</OutlinedButton>
            </FormGroup>
            {/* } */}
          </div>
        </div>
      </div>
      {searchData.length ? (
        <div className="white-box-wrapper" style={{ marginTop: "50px" }}>
          <div className="" style={{ float: "right", marginBottom: "20px" }}>
            {accountType === "FREE" ? (
              <Popover content={pleaseSubscribe} className="">
                <Button className="primary-button not-allowed-button">
                  Save
                </Button>
              </Popover>
            ) : (
              <Button className="primary-button" onClick={openClickModal}>
                Save
              </Button>
            )}
          </div>

          <Row className="fb_interest_list_page" style={{ clear: "both" }}>
            <List
              className="fb_hidden_list"
              size="small"
              header={
                <Row>
                  <Col span={2}>
                    {searchData.length === favouriteCount ? (
                      <StarFilled
                        style={{ color: "#e7ec0e" }}
                        onClick={(e) => handleOnSelectAll(false)}
                      />
                    ) : (
                      <StarOutlined
                        style={{ color: "#ffffff" }}
                        onClick={(e) => handleOnSelectAll(true)}
                      />
                    )}
                  </Col>
                  <Col span={22}>Ads</Col>
                </Row>
              }
              bordered
              dataSource={searchData.length ? searchData : []}
              renderItem={(item, index) => (
                <List.Item>
                  <Col span={2}>
                    {item.isFav ? (
                      <StarFilled
                        style={{ color: "#e7ec0e" }}
                        onClick={(e) => handleOnSelect(item, false)}
                      />
                    ) : (
                      <StarOutlined
                        style={{ color: "#175BCB" }}
                        onClick={(e) => handleOnSelect(item, true)}
                      />
                    )}
                  </Col>
                  <Col
                    span={22}
                    style={{ whiteSpace: "pre-wrap", display: "flex" }}
                  >
                    {!isEditable[index] ||
                    isEditable[index] === "NON_EDITABLE" ? (
                      <div style={{ width: "100%" }}>{item.adText}</div>
                    ) : searchType === "FACEBOOK" ? (
                      <Input
                        className=""
                        value={item.adText}
                        onChange={(e) =>
                          handleTextChange(e.target.value, index)
                        }
                      />
                    ) : (
                      <TextArea
                        className=""
                        value={item.adText}
                        rows={6}
                        autoSize={{ minRows: 1, maxRows: 6 }}
                        // autoSave={true}
                        // showCount
                        maxLength={400}
                        onChange={(e) =>
                          handleTextChange(e.target.value, index)
                        }
                      />
                    )}
                    <span
                      className="editable-ai"
                      style={{ float: "right", marginLeft: "5px" }}
                    >
                      {!isEditable[index] ||
                      isEditable[index] === "NON_EDITABLE" ? (
                        <span
                          title="Update text"
                          onClick={() => makeEditable("EDITABLE", index)}
                          style={{ cursor: "pointer" }}
                        >
                          <EditOutlined />
                        </span>
                      ) : (
                        <span
                          onClick={() => makeEditable("NON_EDITABLE", index)}
                          style={{ cursor: "pointer" }}
                        >
                          <CheckCircleOutlined />
                        </span>
                      )}
                    </span>
                  </Col>
                </List.Item>
              )}
            />
          </Row>
          <div className="form-group text-center">
            <Button
              className="primary-button"
              onClick={generateResponse}
              disabled={loading}
            >
              {loading ? "Generating..." : "Load more"}
            </Button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default AiAdsGenerator;
